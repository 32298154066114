<template>
  <div class="w-full">
    <SuccessButton
      :disabled="!$atividades.permissoes.includes(14)"
      class="mr-6"
      @func="modalCadastroCidade = true"
    ></SuccessButton>
    <SideBar
      title="Cadastrar cidade"
      @hide="modalCadastroCidade = false"
      :active="modalCadastroCidade"
      size="small"
      @success="submitForm()"
    >
      <div slot="body">
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col vs-w="12">
            <vs-input
              class="inputx w-full"
              name="cidadeNome"
              v-validate="'required'"
              label-placeholder="Nome"
              v-model="cidade.nome"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('cidadeNome')"
              >{{ $validators.empty }}</span
            >
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js"

export default {
  name: "ModalCadastroCidade",
  data () {
    return {
      cidade: {},
      modalCadastroCidade: false,
      error: false
    }
  },
  methods: {
    async submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.cadastrarCidade()
        } else {
          this.error = true
          this.$vs.notify(this.$notify.Empty)
        }
      })
    },
    async cadastrarCidade () {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.$vs.loading()
          try {
            const res = await this.$http.post("cidade", this.cidade)
            let logData = {
              id_colaborador: localStorage.getItem("id"),
              funcao: 'cadastrar',
              setor: 'comercial',
              ip: window.localStorage.getItem("ip"),
              texto: 'Cadastro da cidade N°' + res.id
            }
            await this.$logger(logData)

            this.cidade = {}
            this.$vs.notify(this.$notify.Success)
            this.modalCadastroCidade = false
          } catch (err) {
            const error = this.$httpErrors(err)
            this.$vs.notify(error)
          } finally {
            await this.$emit("update")
            this.$vs.loading.close()
          }
        }
      })
    }
  },
  components: components
};
</script>

<style></style>
