<template>
  <div>
    <EditButton
      :disabled="!$atividades.permissoes.includes(14)"
      @func="modalEditarCidade = true"
    ></EditButton>
    <SideBar
      title="Editar cidade"
      @hide="cancelar"
      :active="modalEditarCidade"
      textSucces="Atualizar"
      icon="edit"
      size="small"
      :disabled="!$atividades.permissoes.includes(15)"
      :item="cidade"
      @excluir="excluirCidade()"
      @status="mudarStatusCidade()"
      @success="submitForm()"
    >
      <div slot="body">
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col vs-w="12">
            <vs-input
              class="inputx w-full"
              name="cidadeNome"
              v-validate="'required'"
              label-placeholder="Nome"
              v-model="cidade.nome"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('cidadeNome')"
              >{{ $validators.empty }}</span
            >
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js"

export default {
  name: "modalEditarCidade",
  props: { cidade: Object },
  data () {
    return {
      modalEditarCidade: false,
      error: false,
      originalData: {}
    }
  },
  mounted () {
    this._beforeEditingCache = Object.assign({}, this.cidade)
    this.originalData = this.cidade
  },
  methods: {
    async submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.editarCidade()
        } else {
          this.error = true
          this.$vs.notify(this.$notify.Empty)
        }
      })
    },
    async editarCidade () {
      this.$vs.loading()
      try {
        const res = await this.$http.put(`cidade/${this.cidade.id}`, this.cidade)
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: 'atualizar',
          setor: 'comercial',
          ip: window.localStorage.getItem("ip"),
          texto: 'Atualização da cidade ' + res.id
        }
        await this.$logger(logData)

        this.$vs.notify(this.$notify.Success)
        this.modalEditarCidade = false
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$vs.loading.close()
      }
    },
    async excluirCidade () {
      this.$vs.loading()
      try {
        await this.$http.delete(`cidade/${this.cidade.id}`)
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: 'excluir',
          setor: 'comercial',
          ip: window.localStorage.getItem("ip"),
          texto: 'Exclusão da cidade N°' + this.cidade.id
        }
        await this.$logger(logData)

        this.$vs.notify(this.$notify.Success)
        this.modalEditarCidade = false
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$vs.loading.close()
        this.$emit("update")
      }
    },
    async mudarStatusCidade () {
      this.$vs.loading()
      try {
        await this.$http.put(`cidade/${this.cidade.id}`, {
          lixeira: !this.cidade.lixeira
        })
        this.$vs.notify(this.$notify.Success)
        this.modalEditarCidade = false
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$vs.loading.close()
        this.$emit("update")
      }
    },
    cancelar () {
      Object.assign(this.cidade, this._beforeEditingCache)
      this.originalData = this._beforeEditingCache = null
      this.modalEditarCidade = false
    }
  },
  components: components
};
</script>

<style></style>
